import { Controller } from 'stimulus'

export default class extends Controller {

  static values = {
     basePath: String,
     messageCount: Number
  }

  connect() {
    window.oncontextmenu = () => {
      return this.element.dataset.allowDownloads === 'true';
    }

    // When user hits a conversation view that specifies a message ID like this:  https://accountslug.clarityflow.com/c/conversationslug/#message-123, we must scroll to that message on page load.
    var base_path = this.basePathValue;
    var message_count = this.messageCountValue;

    if (window.location.hash) {
      var message_anchor = window.location.hash;
      var message_id = message_anchor.split("-").pop();
      var new_message_thread = message_anchor.split("#").pop();
      var message_container = document.querySelector(".message#message-"+message_id);

      if (new_message_thread === 'new_message_thread')
      {
        var element = document.querySelector("#new-message-btn-bottom button");
        if (!element) {
          element = document.querySelector("#new-message-btn-top button");
        }
        setTimeout(() => { element?.click() }, 1000);
      } else if(message_container) {
        // the message is a top-level message.  We can scroll right to it.
        message_container.scrollIntoView({behavior: "smooth", block: "start"});

          if (message_count > 1) {
              var request_link = document.createElement('a');
              request_link.href = "/" + base_path + "/scroll_to_threaded_message?message_id=" + message_id;
              request_link.setAttribute("data-remote", "true");
              document.querySelector("body").appendChild(request_link);
              request_link.click();
              request_link.remove();
          }
        this.updateConversationUrlMessageId(message_id)

      } else {
        // load more link will only be visible for conversations with more than 8 messages.
        var link = document.querySelector('.load-all-messages');
        link ??= document.querySelector('.load-few-messages');
        if (link) {
          link.href += `&message_id=${message_id}`;
          link.click()
        } else {
          // message_container wasn't found so we'll assume the message is probably inside a message thread, but hasn't been loaded into the DOM yet.

          // Insert a link element that points to the scroll_to_threaded_message endpoint and click it.
          var request_link = document.createElement('a');
          request_link.href = "/"+base_path+"/scroll_to_threaded_message?message_id="+message_id;
          request_link.setAttribute("data-remote", "true");
          document.querySelector("body").appendChild(request_link);
          request_link.click();
          request_link.remove();

          this.updateConversationUrlMessageId(message_id)
        }
      }
    }

  }

  //When viewing a single message thread, the conversation show link's #message- will point to the specific message provided in the browser URL.
  updateConversationUrlMessageId(message_id){
    var singleMessageViewConversationShowLinks = document.querySelectorAll('.single-message-view-conversation-show-link')
    if (singleMessageViewConversationShowLinks.length > 0) {
      singleMessageViewConversationShowLinks.forEach(element => {
        var link = element.querySelector('a');
        var hrefParts = link.href.split('-');
        hrefParts[hrefParts.length - 1] = message_id;
        link.href = hrefParts.join('-');
      });
    }
  }
}
