import {Controller} from 'stimulus'

export default class extends Controller {

  static targets = ["openWidget", "closeWidget", "supportMenu"]

  connect() {

    // Helpscout beacon code
    !function (e, t, n) {
      function a() {
        var e = t.getElementsByTagName("script")[0], n = t.createElement("script");
        n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e)
      }

      if (e.Beacon = n = function (t, n, a) {
        e.Beacon.readyQueue.push({method: t, options: n, data: a})
      }, n.readyQueue = [], "complete" === t.readyState) return a();
      e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
    }(window, document, window.Beacon || function () {
    });

    window.Beacon('init', '2add2b0f-7ef9-474f-859a-84e56ae7ffe4', {
      style: "manual"
    })

    Beacon('on', 'close', () => this.close_widget());

  }

  open_menu(event) {
    this.supportMenuTarget.classList.remove("hidden");
    this.closeWidgetTarget.classList.remove("hidden");
    this.openWidgetTarget.classList.add("hidden");
  }

  close_menu(event) {
    this.supportMenuTarget.classList.add("hidden");
    this.closeWidgetTarget.classList.add("hidden");
    this.openWidgetTarget.classList.remove("hidden");
  }

  clickOutsideClose(event) {
    var support_widget_container = document.querySelector(".support-widget-container");
    var trigger_link = event.target.closest(".open-support-widget");
    if (!trigger_link && support_widget_container.contains(event.target) === false) {
      this.close_menu();
    }
  }

  open_contact(event) {
    this.supportMenuTarget.classList.add("hidden");
    // HelpScout
    window.Beacon('open');
    document.querySelector(".BeaconContainer").classList.add("mb-12");
    this.openWidgetTarget.classList.add("hidden");

    window.Beacon('prefill', {
      name: event.target.dataset.userName,
      email: event.target.dataset.userEmail
    })

    if (event.target.dataset.openContactForm == "true") {
      window.Beacon('navigate', '/ask/');
    }
  }

  close_widget(event) {
    this.closeWidgetTarget.classList.add("hidden");
    this.openWidgetTarget.classList.remove("hidden");

    // Helpscout
    window.Beacon('close');
    document.querySelector(".BeaconContainer").classList.add("mb-12");
    if (this.hasOpenWidgetTarget) {
      this.openWidgetTarget.classList.remove("hidden");
    }
  }

  report_message(event) {
    var report_message_link = event.target.closest(".report-message-link");
    var report_message_modal = report_message_link.closest(".modal");

    // Close and remove the modal
    report_message_modal.remove();

    // Open the HelpScout beacon
    window.Beacon('open');
    document.querySelector(".BeaconContainer").classList.add("mb-12");

    // Prefill the contact form
    window.Beacon('prefill', {
      name: report_message_link.dataset.userName,
      email: report_message_link.dataset.userEmail,
      subject: "Reporting inappropriate content on Message ID: " + report_message_link.dataset.messageId,
      text: "I'd like to report this message (reference ID: " + report_message_link.dataset.messageId + ") as inappropriate."
    })

    // Navigate the beacon to the contact form
    window.Beacon('navigate', '/ask/message/');
  }

}